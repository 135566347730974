import React, {
  useState,
  useCallback,
  useLayoutEffect,
  useEffect,
} from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  FormGroup,
  InputGroup,
  Input,
  InputGroupText,
  Alert,
  FormFeedback,
} from "reactstrap";
import { BiUserCircle } from "react-icons/bi";
import { MdOutlineChangeCircle } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import toTitleCase from "../../lib/titleCase/TitleCase";
import {
  setAccessToken,
  setRefreshToken,
  setUserDetails,
  setUserPermission,
  setlogin_time,
  setresetAuthenticationState,
} from "../../store/authentication/Authentication";
import { ServerAddress } from "../../constants/ServerAddress";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Icon } from "@iconify/react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import SearchInput from "../formComponent/searchInput/SearchInput_header";
import { IconContext } from "react-icons";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { setPermission } from "../../store/permissions/Permissions";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';


const ProfileMenu = (apiCall) => {
  const queryClient = new QueryClient();
  const user_info = useSelector((state) => state.authentication.userdetails);

  // Declare a new state variable, which we'll call "menu"
  const accessTK = useSelector((state) => state.authentication.access_token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const userdetails = useSelector((state) => state.authentication.userdetails);
  const user = useSelector((state) => state.authentication.username);
  const lastlogin_time = useSelector((state) => state.authentication.login_time);


  const user_id = useSelector((state) => state.authentication.userdetails.id);
  const login_id = useSelector((state) => state.authentication.login_id);
  const refresh_token = useSelector(
    (state) => state.authentication.refresh_token,
  );


  const send_logout_time = (logout_type) => {
    axios
      .post(
        ServerAddress + "api/logout_user_details/",
        {
          refresh_token: refresh_token,
          logout_type: logout_type,
        },
        {
          headers: { Authorization: `Bearer ${accessTK}` },
        },
      )
      .then(function () {
        dispatch(setPermission(false));
        localStorage.clear();
        sessionStorage.clear();
        // window.location.reload();
      })
      .catch(function (e) {
        console.log("error me kya ha ", e)
      });
  };

  const handleApi = useCallback(async () => {
    try {
      await apiCall();
    } catch (error) { }
  }, [apiCall]);


  const [timeDifference, setTimeDifference] = useState(null);


  console.log("timeDifference", timeDifference)


  useEffect(() => {
    const calculateTimeDifference = () => {
      const currentTime = new Date().toLocaleTimeString('en-GB');

      const getTimeInMinutes = (timeStr) => {
        if (!timeStr) return 0;
        const [hours, minutes, seconds] = timeStr.split(':').map(Number);
        return hours * 60 + minutes + (seconds || 0) / 60;
      };
      if (lastlogin_time) {
        const lastlogin = lastlogin_time.split(', ')[1];
        if (lastlogin) {
          const lastLoginMinutes = getTimeInMinutes(lastlogin);
          const currentTimeMinutes = getTimeInMinutes(currentTime);
          let timediff = currentTimeMinutes - lastLoginMinutes;
          if (timediff < 0) {
            timediff += 24 * 60;
          }
          setTimeDifference(timediff);
        }
      }
    };
    calculateTimeDifference();
  }, []);


  const [date_difference, setdate_difference] = useState(false)
  useEffect(() => {
    const currentTime = new Date().toLocaleString('en-GB');
    const current_date = currentTime.split(', ')[0];

    const lastlog_date = lastlogin_time?.split(', ')[0];
    if (lastlog_date != current_date) {
      setdate_difference(true)
    } else {
      setdate_difference(false)
    }

  }, [])



  // Handle tab close/logout
  useLayoutEffect(() => {
    let timeoutId;
    const resetTimer = () => {
      const currentTime = new Date().toLocaleString('en-GB');
      dispatch(setlogin_time(currentTime))
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        send_logout_time('auto');
        dispatch(setUserPermission(null));
        dispatch(setUserDetails(null));
        dispatch(setAccessToken(""));
        dispatch(setRefreshToken(""));
        dispatch(setresetAuthenticationState());
        queryClient.clear();
        localStorage.clear();
        sessionStorage.clear();
        navigate("/signin");
        // window.location.reload();
      }, 1800000); //1800000 Adjust this timeout as needed 10599
    };
    if (accessTK) {
      resetTimer();
    }
    document.addEventListener("mousemove", resetTimer);
    document.addEventListener("mousedown", resetTimer);
    document.addEventListener("keypress", resetTimer);



    return () => {
      clearTimeout(timeoutId);

      document.removeEventListener("mousemove", resetTimer);
      document.removeEventListener("mousedown", resetTimer);
      document.removeEventListener("keypress", resetTimer);

      window.removeEventListener("beforeunload", send_logout_time);

    };
  }, [navigate, send_logout_time, dispatch, handleApi]);


  useEffect(() => {
    if (timeDifference > 30 || date_difference) {
      send_logout_time('auto');
      dispatch(setUserPermission(null));
      dispatch(setUserDetails(null));
      dispatch(setAccessToken(""));
      dispatch(setRefreshToken(""));
      dispatch(setresetAuthenticationState());
      localStorage.clear();
      sessionStorage.clear();
      navigate("/signin");
      // window.location.reload();
    }
  }, [timeDifference])


  //Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);




  const [old_password, setold_password] = useState("");
  const [new_password, setnew_password] = useState("");
  const [confirm_password, setconfirm_password] = useState("");
  const [old_password_error, setold_password_error] = useState(false);
  const [new_password_error, setnew_password_error] = useState(false);
  const [confirm_password_error, setconfirm_password_error] = useState(false);
  // error message if not matched password
  const [error, seterror] = useState(false);
  // Open Modal for change password
  const [openModal, setopenModal] = useState(false);
  const closeModal = () => {
    setold_password("");
    setnew_password("");
    setconfirm_password("");
    setcheck_Regex(false);
    setopenModal(false);
    seterror(false);
  };
  const [showPass, setshowPass] = useState(false);
  const [showPass1, setshowPass1] = useState(false);
  const passwordRegex = /^(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,}$/;
  const [check_Regex, setcheck_Regex] = useState(false);
  const [check_pass, setcheck_pass] = useState(false);
  const validatePassword = (password) => {
    return passwordRegex.test(password);
  };
  const ResetPassword = () => {
    axios
      .put(
        ServerAddress + "changepwd/",
        {
          username: userdetails.email,
          old_password: old_password,
          new_password: new_password,
        },
        {
          headers: {
            Authorization: `Bearer ${accessTK}`,
          },
        },
      )
      .then(function (response) {
        console.log("response is", response.data);
        if (response.data === "Password Reset Successfully") {
          setopenModal(false);
          alert("Password Reset SuccessFully, Login Again");
          seterror(false);
          send_logout_time('manual');
          dispatch(setUserDetails(null));
          dispatch(setAccessToken(""));
          dispatch(setRefreshToken(""));
          localStorage.clear();
          navigate("/");
        } else if (response.data === "Old Password Wrong") seterror(true);
      })
      .catch(function () {
        seterror(true);
      });
  };
  useLayoutEffect(() => {
    if (old_password !== "") {
      setold_password_error(false);
    }
    if (new_password !== "") {
      setnew_password_error(false);
    }
    if (validatePassword(new_password)) {
      setcheck_Regex(false);
    }
    if (confirm_password !== "") {
      setconfirm_password_error(false);
    }
    if (new_password === confirm_password) {
      setcheck_pass(false);
    }
  }, [old_password, new_password, confirm_password]);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (old_password === "") {
      setold_password_error(true);
    } else if (new_password === "") {
      setnew_password_error(true);
    } else if (!validatePassword(new_password)) {
      setcheck_Regex(true);
    } else if (confirm_password === "") {
      setconfirm_password_error(true);
    } else if (new_password !== confirm_password) {
      setcheck_pass(true);
    } else {
      ResetPassword();
      setcheck_Regex(false);
    }
  };


  return (
    <>
      <Modal
        show={openModal}
        onHide={() => {
          closeModal();
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        {check_Regex && (
          <div
            style={{
              textAlign: "center",
              padding: "8px",
              background: "#E6F1FF",
              color: "#EA7878",
              fontSize: "15px",
            }}
          >
            Password must start with a capital letter, contain at least one
            special character (@, #, $, %, ^, &, +, or =), and be longer than 8
            characters.
          </div>
        )}
        {check_pass && (
          <div
            style={{
              textAlign: "center",
              padding: "8px",
              background: "#E6F1FF",
              color: "#EA7878",
              fontSize: "15px",
            }}
          >
            New Password Must Be Equal To Confirm Password
          </div>
        )}

        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {error ? <Alert color="danger">Old Password Wrong</Alert> : null}
            <FormGroup className="form-group">
              <Label for="old_password" className="form-label">
                Old Password
              </Label>
              <InputGroup>
                <Input
                  type={showPass ? "text" : "password"}
                  name="old_password"
                  id="old_password"
                  className="form-input"
                  placeholder="Enter Old Password"
                  value={old_password}
                  onChange={(val) => setold_password(val.target.value)}
                  invalid={old_password_error}
                />
                <InputGroupText>
                  <IconContext.Provider value={{ size: 16 }}>
                    <div
                      onClick={() => {
                        setshowPass(!showPass);
                      }}
                    >
                      {showPass ? (
                        <FaEyeSlash style={{ size: 30 }} />
                      ) : (
                        <FaEye />
                      )}
                    </div>
                  </IconContext.Provider>
                </InputGroupText>
                {old_password_error && (
                  <FormFeedback type="invalid">
                    {"Write Old Password"}
                  </FormFeedback>
                )}
              </InputGroup>
            </FormGroup>
            <FormGroup className="form-group">
              <Label for="new_password" className="form-label">
                New Password
              </Label>
              <Input
                type="password"
                name="new_password"
                id="new_password"
                className="form-input"
                placeholder="Enter New Password"
                value={new_password}
                onChange={(val) => setnew_password(val.target.value)}
                onBlur={() => {
                  if (!validatePassword(new_password)) {
                    setcheck_Regex(true);
                  }
                }}
                invalid={new_password_error}
              />
              {new_password_error && (
                <FormFeedback type="invalid">
                  {"Write New Password"}
                </FormFeedback>
              )}
            </FormGroup>
            <FormGroup className="form-group">
              <Label for="confirm_password" className="form-label">
                Confirm Password
              </Label>
              <InputGroup>
                <Input
                  type={showPass1 ? "text" : "password"}
                  name="confirm_password"
                  id="confirm_password"
                  className="form-input"
                  placeholder="Enter Confirm Password"
                  value={confirm_password}
                  onChange={(val) => setconfirm_password(val.target.value)}
                  invalid={confirm_password_error}
                />
                <InputGroupText>
                  <IconContext.Provider value={{ size: 16 }}>
                    <div
                      onClick={() => {
                        setshowPass1(!showPass1);
                      }}
                    >
                      {showPass1 ? (
                        <FaEyeSlash style={{ size: 30 }} />
                      ) : (
                        <FaEye />
                      )}
                    </div>
                  </IconContext.Provider>
                </InputGroupText>
                {confirm_password_error && (
                  <FormFeedback type="invalid">
                    {"Write Confirm Password"}
                  </FormFeedback>
                )}
              </InputGroup>
            </FormGroup>
            <Button
              color="primary"
              type="submit"
              className="btn btn-info m-1"

            >
              Change Password
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="secondary"
            className="btn btn-warning m-1"
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <React.Fragment>
        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="d-inline-block"
        >
          <div>
            <DropdownToggle
              className="btn header-item"
              id="page-header-user-dropdown"
              tag="button"
              style={{position: "relative" }}
            >
              <div style={{ display: "flex" }}>
                <BiUserCircle size={22} />
                <span className="d-none d-xl-inline-block ms-2 me-1">
                  {toTitleCase(userdetails.username)}(
                  <span>{toTitleCase(userdetails.home_branch_name)}</span>)
                </span>
                <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
              </div>
            </DropdownToggle>

            <DropdownMenu
              className="dropdown-menu-end"
              style={{
                position: "fixed",
                top: "0", // Adjust this value if you want some margin from the top
                right: "0", // Adjust this if you want the menu closer to the right edge
                zIndex: "200", // Ensure it appears above other content
              }}
            >
              {user_info.is_onboarded && (
                <Link to="/userProfile/Profile" className="dropdown-item hover-header">
                  <i className="bx bx-user font-size-16 align-middle me-1" />
                  Profile
                </Link>
              )}

              {user_info.is_onboarded && (
                <div onClick={handleShow} className="dropdown-item hover-header" style={{ display: "flex", gap: "6px" }}>
                  <MdOutlineChangeCircle size={18} />
                  <span>Switch Organisation</span>
                </div>
              )}

              {user_info.is_onboarded && (
                <div className="dropdown-item hover-header" style={{ display: "flex", gap: "6px" }}>
                  <i className="bx bx-reset font-size-20 align-middle me-1" />
                  <span onClick={() => setopenModal(true)}>Change Password</span>
                </div>
              )}

              <div className="dropdown-divider" />

              <Link
                to="/signin"
                className="dropdown-item"
                onMouseDown={() => {
                  send_logout_time("manual");
                  dispatch(setAccessToken(""));
                  dispatch(setRefreshToken(""));
                  dispatch(setUserDetails(null));
                  dispatch(setUserPermission(null));
                  dispatch(setresetAuthenticationState());
                  dispatch(setPermission(false));
                  localStorage.clear();
                  navigate("/");
                }}
              >
                <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                <span>Logout</span>
              </Link>
            </DropdownMenu>
          </div>


        </Dropdown>
      </React.Fragment>
    </>
  );
};

export default ProfileMenu;
