import React, { useLayoutEffect, useState, useEffect } from "react";
import { auth_routes, routes } from "./routes";
import { Route, Routes, useNavigate, Navigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
// Import scss
import "./assets/scss/theme.scss";

import { useDispatch, useSelector } from "react-redux";
import Layout from "./components/layout";
import Dashboard from "./screens/dashboard/Dashboard";
import { setAccessToken, setRefreshToken, setUserDetails } from "./store/authentication/Authentication";

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.authentication.userdetails);
  const access_token = useSelector((state) => state.authentication.access_token);
  const location = useLocation(); // Get the current location
  const setpermission = useSelector((state) => state.permissions.setpermission);
  const [s_route, sets_route] = useState(auth_routes);

  // useEffect(() => {
  //   if (!setpermission) {
  //     dispatch(setUserDetails(null));
  //     dispatch(setAccessToken(""));
  //     dispatch(setRefreshToken(""));
  //     navigate("/signin");
  //   }
  // }, [setpermission,location.pathname]);


  useEffect(() => {
    if (auth_routes.some(route => route.path === location.pathname && !setpermission)) {
      navigate(location.pathname);
    } else if (!setpermission) {
      dispatch(setUserDetails(null));
      dispatch(setAccessToken(""));
      dispatch(setRefreshToken(""));
      navigate("/signin");
    }
  }, [setpermission])

console.log("access_token==",access_token)
console.log("userData==",userData)
console.log("setpermission==",setpermission)

  useLayoutEffect(() => {
    let data = routes;
    if (userData && setpermission) {
      if (userData?.is_onboarded && setpermission) {
        data.push(routes);
      } else {
        data = [
          {
            path: "/dashboard",
            element: <Navigate to="/ems/employee/UserStepForm" />,
          },
          ...data.filter((route) => route.path !== "/dashboard"),
        ];
      }
      sets_route(data);
    } else if (!access_token){
      sets_route(auth_routes);
    }
  }, [userData,routes]);

  // to lock screen by clicking shift + L
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const handleKeyPress = (event) => {
      // if (event.key === 'L')
      // Window + Shift + L
      if (event.key === "L" && event.shiftKey && event.metaKey) {
        setIsDisabled(!isDisabled);
        document.body.style.pointerEvents = isDisabled ? "auto" : "none";
        document.onkeydown = isDisabled ? null : () => false;
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [isDisabled]);

  // return(
  //   <Dashboard/>
  // )

  return (
    <>
      <Routes>
        {userData != null ? (
          <Route path="/" element={<Layout />}>
            {s_route.map((item, index) => {
              return (
                <Route path={item.path} element={item.element} key={index} />
              );
            })}
          </Route>
        ) : (
          <>
            {s_route.map((item, index) => {
              return (
                <Route path={item.path} element={item.element} key={index} />
                // <Route path={"/"} element={<Navigate to="/signin" />} />
              );
            })}
          </>
        )}
      </Routes>
    </>
  );

};

export default App;
