import React, { useState, useEffect } from "react";
import Header from "./Header";
import { Outlet } from "react-router";
import SideBar from "./SideBar";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import { setNavToggle } from "../../store/dataList/DataList";

const Layout = () => {
  const dispatch = useDispatch();
  const accessTK = useSelector((state) => state.authentication.access_token);
  const user_info = useSelector((state) => state.authentication.userdetails);
  const nav_toggle = useSelector((state) => state.datalist.nav_toggle);
  const [innerWidth, setinnerWidth] = useState();

  let wind_Width = window.innerWidth;
  useEffect(() => {
    setinnerWidth(wind_Width);
  }, [wind_Width]);

  useEffect(() => {
    dispatch(setNavToggle(false));
  }, []);

  const handleClk = () => {
    if (innerWidth < 1200) {
      dispatch(setNavToggle(true));
    }
  };

  // close sidebar when onboarderd employee false
  useEffect(() => {
    if (!user_info.is_onboarded) {
      dispatch(setNavToggle(true));
    }
  }, [user_info]);
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        overflow: "auto",
      }}
    >
      {!nav_toggle && (
        // <div className={innerWidth < 1200 ? "min_side_bar" : "side_bar"}>

        <div style={{ flex: "1" }}>
          {((!user_info.is_superuser && user_info.is_onboarded) ||
            user_info.is_superuser) && <SideBar />}
        </div>
      )}
      <div style={{ flex: "5" }}>
        {accessTK &&
          <div
            style={{
              background: "#FFFFFF",
              height: "7%",
              display: "flex",
              justifyContent: "space-between",
              top: "0",
              zIndex: "1",
            }}
          >
            <Header />
          </div>
        }
        <div
          id={nav_toggle ? "max_outlet" : "min_outlet"}
          style={{
            background: "",
            height: accessTK ? "86%" : '100%',
            overflowX: "hidden",
            overflowY: "scroll",
            paddingTop: "6px",
            margin: "0px",
            // width: "100%",
          }}
          onClick={() => handleClk()}
        >
          <Outlet />
        </div>
        {accessTK &&
          <div
            style={{
              background: "#FFFFFF",
              height: "7%",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Footer />
          </div>
        }
      </div>
    </div>
    // <div>
    //   <div style={{width:"100%", background:"green"}}>A</div>
    //   <div style={{width:"100%", background:"red"}}>B</div>
    // </div>
  );
};

export default Layout;
